import { Box } from '@chakra-ui/layout';
import {
  Table as BaseTable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/table';
import { toCapitalizedSentence } from '@utils/strings';

interface TableProps {
  dataset: Array<object>;
}

// Returns an array of unique table headers from the keys of the objects in the dataset.
const getTableHeaders = (data: Array<object>) => {
  const keys = data.reduce(
    (acc: Array<string>, cur: object) => acc.concat(Object.keys(cur)),
    []
  );
  const set = new Set(keys);
  return Array.from(set);
};

const Table = ({ dataset }: TableProps): React.ReactElement => {
  const tableHeaders = getTableHeaders(dataset);

  return (
    <Box
      bg={'white'}
      border="1px"
      borderColor="gray.200"
      borderRadius={'xl'}
      p={3}
    >
      <TableContainer overflowX="auto" whiteSpace="normal">
        <BaseTable variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {tableHeaders.map((header) => (
                <Th
                  key={header}
                  className="magnolia-text"
                  dangerouslySetInnerHTML={{
                    __html: toCapitalizedSentence(header),
                  }}
                />
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {dataset.map((row, index) => (
              <Tr key={index}>
                {tableHeaders.map((header) => (
                  <Td
                    key={header}
                    className="magnolia-text"
                    minW="150px"
                    dangerouslySetInnerHTML={{
                      __html: row[header],
                    }}
                  />
                ))}
              </Tr>
            ))}
          </Tbody>
        </BaseTable>
      </TableContainer>
    </Box>
  );
};

export default Table;
